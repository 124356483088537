"use client";
import styles from "./index.module.scss";
import { useParams } from "next/navigation";
import cn from "classnames";
import LeaderboardInterface, {
  LeaderboardItem,
} from "@/types/leaderboardInterface";
import ReactCountryFlag from "react-country-flag";
import { Activity } from "@/utils/constants";
import React from "react";

const Item = React.memo(
  ({
    item,
    active,
  }: {
    item: LeaderboardItem | LeaderboardInterface["user"];
    active: boolean;
  }) => {
    const params: { activity: string } = useParams();

    const getTotal = (item: LeaderboardItem) => {
      return item.points;
    };
    const getSkillPoints = (item: LeaderboardItem) => {
      if (params.activity === "overall") {
        return item.performances_points
          .filter((item) => item.performance !== "overall")
          .map((item, key) => (
            <div key={key} className={styles.scoreItem}>
              <div className={styles.scoreName}>{item.performance}</div>
              <div className={styles.scoreVal}>
                {item.points !== null ? item.points : "-"}
              </div>
            </div>
          ));
      } else {
        const activity = item.performances_points.find(
          (i) => i.performance === params.activity,
        );
        if (!activity) {
          return null;
        }
        return (
          <div className={cn(styles.scoreItem, styles.skill)}>
            <div className={styles.scoreVal}>
              {activity.value !== null ? `${activity.value}` : "-"}
            </div>
            <div className={styles.scoreName}>
              {Activity[`${activity.performance}Val`]}
            </div>
          </div>
        );
      }
    };

    if (!item) return null;
    return (
      <div className={cn(styles.item, active && styles.active)} key={item.id}>
        <div className={styles.left}>
          <div className={styles.position}>{item.position}</div>

          <div className={styles.name}>
            {" "}
            <div className={styles.flag}>
              <ReactCountryFlag
                countryCode={item.country}
                svg
                style={{
                  width: "auto",
                  height: "calc(22px * var(--scale))",
                }}
              />
            </div>
            {item.name}
          </div>
        </div>
        <div className={styles.right}>{getSkillPoints(item)}</div>
        <div className={styles.total}>{getTotal(item)}</div>
      </div>
    );
  },
);

export default Item;
