"use client"
import { useQueryClient } from "@/utils/@tanstack/react-query";
import { useGetLeaderboard, useGetLeaderboardLatest } from "@/hooks";
import styles from "./index.module.scss";
import { useParams } from "next/navigation";
import cn from "classnames";
import Badge from "../badge";
import { useEffect, useState } from "react";
import Item from "./item";
import LeaderboardInterface from "@/types/leaderboardInterface";

const isPortrait = (window.innerHeight > window.innerWidth && window.innerHeight > 1400);

export default function Leaderboard() {
  const params: { activity: string } = useParams();
  const { data: latest } = useGetLeaderboardLatest(params.activity);
  const [latestId, setLatestId] = useState<null | { participant_id: number, updated: number }>(null);
  const { data } = useGetLeaderboard(params.activity, latestId, isPortrait);
  const [showPrevData, setShowPrevdata] = useState<LeaderboardInterface>()
  // const { data: init } = useGetInit()
  const queryClient = useQueryClient();
  useEffect(() => {
    if (latest?.participant_id) {
      queryClient.invalidateQueries( {
        queryKey: [`event/leaderboard/${params.activity}?limit=5&participant=${latest.participant_id}`],
        refetchType: 'active',
      },); 
      setShowPrevdata(data)
      setLatestId(latest);
    }
    // Clear the state after 1 minute
    // const timeoutId = setTimeout(() => {
    //   setLatestId(null);
    // }, 60000);

    // return () => clearTimeout(timeoutId);
  }, [latest?.updated]);

  let leaderboard = data;
  if(!leaderboard && showPrevData) {
    leaderboard = showPrevData;
  }

  let userIsInTop = leaderboard?.top.find((item) => item.id === leaderboard?.user?.id);
  
  return (
    <main className={cn(styles.content, params.activity !== 'overall' && styles.skillLeaderboard)}>
      {/* <div className={cn(styles.list, leaderboard?.user && styles.hasCrest)}> */}
      <div className={cn(styles.list)}>
        {leaderboard?.top.map(item =>
          <Item key={item.id} item={item} active={params.activity !== 'overall' && item.id === leaderboard?.user?.id} />
        )}
        {params.activity !== 'overall' && !userIsInTop && leaderboard?.user &&
          <Item key={leaderboard.user.id} item={leaderboard.user} active={leaderboard.user.id === leaderboard?.user?.id} />
        }
      </div>
      {/* {params.activity !== 'overall' &&
        <Badge user={leaderboard?.user} init={init?.data} />
      } */}
    </main>
  );
}
